module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"My Pet Walker","description":"My Pet Walker is a customisable pet services app. It enables more efficient booking, time management and paying while reducing lost revenue through cancellations.","lang":"en","short_name":"My Pet Walker","icon":"src/assets/logo.svg","start_url":"/","background_color":"#70BC50","theme_color":"#70BC50","display":"standalone","prefer_related_applications":true,"related_applications":[{"platform":"play","id":"com.fatbadgermedia.MyPetWalker"},{"platform":"itunes","id":"https://apps.apple.com/gb/app/my-pet-walker/id1484502962"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"67e99ab28ff269cf48cb8232b2f1ab2b"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
